import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import { graphql } from 'gatsby';
import CatListing from "../components/CatListing";
import Banner from "../components/Banner";
import BannerThin from "../components/BannerThin";
import ImageSlider from "../components/ImageSlider";
import DiscoverSnowdoniaMap from "../components/DiscoverSnowdoniaMap";
import language from "../../data/language.yaml";

import {Col, Row} from "antd";
import {shuffle} from "../services/helpers";
import Img from "gatsby-image";
import Link from "../components/Link";

class Index extends Component {
    render() {

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        const cats = this.props.data.allWordpressWpLocationType.edges;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let gallerySlides = "";

        if(acfFields.image_gallery.length > 1) {
            gallerySlides = [];
            acfFields.image_gallery.forEach(element =>
                gallerySlides.push( element.localFile.childImageSharp.fluid)
            );
        }

        let slides = "";

        if(acfFields.image_pool !== null && acfFields.image_pool !== undefined) {
            slides = [];
            acfFields.image_pool.forEach(element =>
                slides.push(element.localFile.childImageSharp.original.src)
            );

            slides = shuffle(slides);
        }

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                {/*<SplashScreen image={slides[0]} locale={page.translations.current}/>*/}
                <div className="home-video-wrap">
                    <div className="responsive-video">
                        <iframe title={"video-banner-"+acfFields.intro_video_id}
                            // src={"https://player.vimeo.com/video/"+acfFields.intro_video_id+"?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=0&amp;autopause=0&amp;muted=0&amp;background=0&amp;fullscreen=1"}
                                src={"https://player.vimeo.com/video/"+acfFields.intro_video_id+"?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=1&muted=1&background=1"}
                                frameBorder="0"
                                allowFullScreen
                        />
                        <div className="framed-content">
                            <div className="frame-con-top" dangerouslySetInnerHTML={{__html: language.homePage.discoverMessage[page.translations.current]}} />
                            <div className="frame-con-bottom">
                                <p>{acfFields.overlay_text}</p>
                                <div className="sponsor">
                                    {language.homePage.routeSponsor[page.translations.current]} <img src="/images/sponsor-logo.png" style={{ width: "35px", paddingLeft: "8px"}} />
                                </div>
                            </div>
                        </div>
                        <div id={'videoOverlay'}></div>
                        <span className="video-after"><span className="dots" /></span><span className="dots" />
                    </div>
                </div>
                <div className="intro home-intro">
                    <Row type="flex">
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <div className={"intro-content"}>
                                <h2>{acfFields.intro_header}</h2>
                            </div>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <div className={"intro-content"}>
                                <div dangerouslySetInnerHTML={{ __html: acfFields.intro_para }}/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="map-wrapper">
                    <DiscoverSnowdoniaMap locale={page.translations.current}/>
                </div>
                <CatListing
                    title={language.homePage.lookingFor[page.translations.current]}
                    locale={page.translations.current}
                    cats={cats}
                />
                <div className="get-map">
                    <Row type="flex">
                        <Col xs={{span: 24}} md={{span: 12}} className="download-col">
                            <div className="col-wrap">
                                <Img fluid={acfFields.download_column.image.localFile.childImageSharp.fluid} />
                                <h2>{acfFields.download_column.header}</h2>
                                <p>{acfFields.download_column.content}</p>
                                <Link className="btn btn-secondary" to={acfFields.download_column.button_link}>
                                    <span>{acfFields.download_column.button_text}</span>
                                    <span className="icon arrow"></span>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12}} className="purchase-col">
                            <div className="col-wrap">
                                <Img fluid={acfFields.purchase_column.image.localFile.childImageSharp.fluid} />
                                <h2>{acfFields.purchase_column.header}</h2>
                                <p>{acfFields.purchase_column.content}</p>
                                <Link className="btn btn-secondary" to={acfFields.purchase_column.button_link}>
                                    <span>{acfFields.purchase_column.button_text}</span>
                                    <span className="icon arrow"></span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Banner
                    bgColor = {acfFields.banner_background_colour}
                    image = {acfFields.banner_image}
                    headerColor = {acfFields.banner_header_colour}
                    header = {acfFields.banner_header}
                    paraColor = {acfFields.banner_para_colour}
                    para = {acfFields.banner_paragraph}
                    btnLink = {acfFields.banner_button_link}
                    btnText = {acfFields.banner_button_text}
                    btnColor = {acfFields.banner_button_colour}
                />
                <BannerThin
                    bgColor = {acfFields.thin_banner_background_colour}
                    headerColor = {acfFields.thin_banner_header_colour}
                    header = {acfFields.thin_banner_header}
                    paraColor = {acfFields.thin_banner_para_colour}
                    para = {acfFields.thin_banner_paragraph}
                    btnLink = {acfFields.thin_banner_button_link}
                    btnText = {acfFields.thin_banner_button_text}
                    btnColor = {acfFields.thin_banner_button_colour}
                />
                <ImageSlider
                    logo={true}
                    slides = {gallerySlides}
                />
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query QueryIndexPage($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      translations {
        current
        en
        cy
      }
      acf {
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        
        intro_header
        intro_para
        intro_video_id
        overlay_text
        banner_background_colour
        banner_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
                fluid(quality: 80) {
                  ... GatsbyImageSharpFluid
                }
            }
          }
        }
        banner_header_colour
        banner_para_colour
        banner_header
        banner_paragraph
        banner_button_colour
        banner_button_text
        banner_button_link
        
        download_column {
          image {
            localFile {
              childImageSharp {
                original {
                  width
                  height
                  src
                }
                fluid(quality: 80, maxWidth: 365, maxHeight: 360) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          header
          content
          button_text
          button_link
        }
        
        purchase_column {
          image {
            localFile {
              childImageSharp {
                original {
                  width
                  height
                  src
                }
                fluid(quality: 80, maxWidth: 365, maxHeight: 360) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          header
          content
          button_text
          button_link
        }
        
        thin_banner_background_colour
        thin_banner_header_colour
        thin_banner_para_colour
        thin_banner_header
        thin_banner_paragraph
        thin_banner_button_colour
        thin_banner_button_text
        thin_banner_button_link
        
        image_gallery {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
              fluid(quality: 80, maxWidth: 1920, maxHeight: 1000) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
        
        image_pool {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
      }
    },
    allWordpressWpLocationType(sort: {fields: menu_order, order: ASC}, filter: {wordpress_parent: {eq: 0}}) {
      edges {
        node {
          id
          title
          slug
          translations {
            current
            en
            cy
          }
          acf {
            listing_blurb
            listing_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            listing_colour
          }
        }
      }
    }
  }
`

export default Index
