import React from 'react'
import './style.less';
import { Carousel } from 'antd';
import S360Logo from "../S360Logo";
import Img from "gatsby-image";

class ImageSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slides: null,
        };
    }

    render(){

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 5000,
            swipeToSlide: true,
        };

        let count = 0;

        return (
            <div className="s360-slider" data-logo={this.props.logo}>
                {this.props.logo === true &&
                <div className="slider-logo">
                    <S360Logo/>
                </div>
                }
                <Carousel className="image-slider" {...settings}>
                    {this.props.slides && this.props.slides.map((image) => {
                        count++;
                        return <div className="slide" key={'slide_' + count}><Img className="slide-image" fluid={image} /></div>
                    })}
                </Carousel>
            </div>
        );
    }
}

export default ImageSlider