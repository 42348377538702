import React from 'react'
import './style.less';
import Link from "../Link";
import Img from "gatsby-image";
import {trackViewMember} from "../../services/gtm";

class CatListingItem extends React.Component {
    render() {
        let key = '';
        if (this.props.location) {
            key = this.props.location.wordpress_id;
        } else {
            key = this.props.urlPath;
        }

        return (
            <Link key={"cli_" + key} onClick={() => trackViewMember(this.props.urlPath)} className="cat-listing-item" to={this.props.urlPath} data-color={this.props.color}>
                {typeof this.props.image === "string" && (<div className="gatsby-image-wrapper" style={{ position : "relative" , overflow: "hidden"}}><img src={this.props.image} alt="" /></div>)}
                {typeof this.props.image === "object" && (<Img fluid={this.props.image}/>)}
                <span className="content-wrap">
                    <span className="title" dangerouslySetInnerHTML={{__html: this.props.title}} />
                    <span className="blurb" dangerouslySetInnerHTML={{__html: this.props.blurb}} />
                </span>
            </Link>
        );
    }
}

export default CatListingItem
